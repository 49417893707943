import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, of, tap, switchMap, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../environments/environments';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    private apiUrl = `${environment.apiUrl}/auth/login`;
    // private apiCsrf = "http://localhost:8000/sanctum/csrf-cookie";
    private apiCsrf = "https://www.educsteam.com/api/public/sanctum/csrf-cookie";

    constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) { }

    // Método para obtener la cookie CSRF
    getCsrfToken_v2(): Observable<void> {
        return this.http.get<void>(this.apiCsrf, { withCredentials: true });
    }

    // Método para autenticarse
    authenticate(email: string, password: string): Observable<any> {
        return this.getCsrfToken_v2().pipe(
            switchMap(() => 
                this.http.post<any>(this.apiUrl, { email, password }, { withCredentials: true })
            ),
            tap((response: any) => {
                localStorage.setItem('authToken', response.access_token);
                localStorage.setItem('userData', JSON.stringify(response.user));
            }),
            catchError(error => {
                console.error('Error during authentication:', error);
                return of(false);
            })
        );
    }

    getUserData() {
        const userData = localStorage.getItem('userData');
        return userData ? JSON.parse(userData) : null;
    }

    logout() {
        localStorage.removeItem('authToken');
        localStorage.removeItem('userData');
    }

    isAuthenticated(): boolean {
        if (isPlatformBrowser(this.platformId)) {
            return !!localStorage.getItem('authToken');
        }
        return false;
    }
}
